<template>
  <section class="container-fluid">
    <v-row>
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center justify-end flex-column"
        :align-self="'center'"
        :align-md-self="'end'"
      >
        <router-link
          :to="{ name: 'sobre_cultura_manifestacoes_tradicionais' }"
          class="d-flex flex-column align-center"
          ><v-img
            max-width="70%"
            src="../../assets/sobre_cultura_bento_01.png"
          ></v-img>
          <p class="orange--text mb-0" style="text-align: center; width: 80%">
            Manifestações tradicionais e festividades religiosas
          </p></router-link
        >
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center justify-start flex-column"
        :align-self="'start'"
        :align-sm-self="'center'"
      >
        <router-link
          :to="{ name: 'sobre_cultura_protecao_patrimonio' }"
          class="d-flex flex-column align-center"
        >
          <v-img
            max-width="60%"
            src="../../assets/sobre_cultura_bento_02.png"
          ></v-img>
          <p class="orange--text mb-5" style="text-align: center;">
            Proteção do Patrimônio Construído
          </p>
        </router-link>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center justify-center flex-column"
        :align-self="'center'"
        :align-md-self="'end'"
      >
        <router-link
          :to="{ name: 'sobre_cultura_capela_sao_bento' }"
          class="d-flex flex-column align-center"
          ><v-img
            max-width="50%"
            src="../../assets/sobre_cultura_bento_03.png"
          ></v-img>
          <p class="orange--text mb-0" style="text-align: center;">
            Capela de São Bento
          </p></router-link
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center justify-end flex-column"
        :align-self="'start'"
        :align-sm-self="'center'"
      >
        <router-link
          :to="{ name: 'sobre_cultura_capela_nsra_merces' }"
          class="d-flex flex-column align-center"
          ><v-img
            max-width="25%"
            max-height="170px"
            src="../../assets/sobre_cultura_bento_04.png"
          ></v-img>
          <p class="orange--text" style="text-align: center;">
            Capela de Nossa Senhora das Mercês
          </p></router-link
        >
      </v-col>

      <v-col
        cols="12"
        md="4"
        class="d-flex align-center justify-start flex-column"
        :align-self="'center'"
        :align-md-self="'end'"
      >
        <router-link
          :to="{ name: 'sobre_cultura_lazer_convivio' }"
          class="d-flex flex-column align-center"
          ><v-img
            max-width="60%"
            src="../../assets/sobre_cultura_bento_05.png"
          ></v-img>
          <p class="orange--text mb-0" style="text-align: center;">
            Lazer e convívio
          </p></router-link
        >
      </v-col>

      <v-col
        cols="12"
        md="4"
        class="d-flex justify-end flex-column"
        :align-self="'start'"
        :align-sm-self="'center'"
      >
        <router-link
          :to="{ name: 'sobre_cultura_capela_nsra_aparecida' }"
          class="d-flex flex-column align-center"
          ><v-img
            max-width="70%"
            src="../../assets/sobre_cultura_bento_06.png"
          ></v-img>
          <p class="orange--text" style="text-align: center;">
            Capela de Nossa Senhora Aparecida
          </p></router-link
        >
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
a {
  text-decoration: none;
}

p {
  font-size: 0.8em;
}

.router-link-active {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #e06919;
}
</style>
